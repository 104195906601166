import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "article" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = ["innerHTML"]

import Navi from '@/components/Navi.vue';
import { lang } from '@/lang';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Article',
  setup(__props) {

const route = useRoute();
const type = route.query.type as 'policy' | 'agreement';

const htmlValue = ref('');

onMounted(() => {
  if (type === 'policy') {
    lang.getPolicy().then(v => (htmlValue.value = v));
  } else if (type === 'agreement') {
    lang.getAgreement().then(v => (htmlValue.value = v));
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Navi, {
      title: _unref(lang).v[_unref(type)],
      onBack: ""
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", { innerHTML: htmlValue.value }, null, 8, _hoisted_3)
    ])
  ]))
}
}

})