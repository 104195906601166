import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/left_jiantou.png'


const _hoisted_1 = { class: "navi row-center" }
const _hoisted_2 = { class: "title center" }
const _hoisted_3 = { class: "right" }


import { useRouter } from 'vue-router';

type OnBackFun = () => Promise<boolean | void> | boolean | void;


export default /*@__PURE__*/_defineComponent({
  __name: 'Navi',
  props: {
    title: {},
    onBack: { type: [Boolean, Function] }
  },
  setup(__props: any) {

const router = useRouter();

const props = __props;

const onClickBack = async () => {
  const backFun = props.onBack;
  if (backFun) {
    if (typeof backFun === 'function') {
      const isStop = await backFun();
      if (!isStop) {
        router.back();
      }
    } else {
      router.back();
    }
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      class: "left-menu",
      onClick: onClickBack
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("img", {
        class: "btn",
        src: _imports_0
      }, null, -1)
    ]), 512), [
      [_vShow, _ctx.onBack]
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("span", null, null, -1)),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "menu")
    ])
  ]))
}
}

})